import React from 'react'
import { Redirect } from 'react-router-dom'
import { Route } from 'react-router-dom'
import * as FeatherIcon from 'react-feather'

import { isUserAuthenticated, getLoggedInUser } from '../helpers/authUtils'

// auth
const Login = React.lazy(() => import('../pages/auth/Login'))
const Logout = React.lazy(() => import('../pages/auth/Logout'))

// Dashboard
const Dashboard = React.lazy(() => import('../pages/dashboard'))
// Yönetici
const YoneticiEkle = React.lazy(() => import('../pages/apps/Yonetici/Ekle'))
const YoneticiGoruntule = React.lazy(() =>
  import('../pages/apps/Yonetici/Goruntule')
)
// Yazar
const YazarEkle = React.lazy(() => import('../pages/apps/Yazar/Ekle'))
const YazarGoruntule = React.lazy(() => import('../pages/apps/Yazar/Goruntule'))
// kullanıcı
const KayitliKullanicilar = React.lazy(() =>
  import('../pages/apps/Kullanici/KayitliKullanicilar')
)
// kategori
const KategoriEkle = React.lazy(() => import('../pages/apps/Kategori/Ekle'))
const KategoriDuzenle = React.lazy(() =>
  import('../pages/apps/Kategori/Duzenle')
)
// Soru
const SoruEkle = React.lazy(() => import('../pages/apps/Soru/Ekle'))
const SoruGoruntule = React.lazy(() => import('../pages/apps/Soru/Goruntule'))
const SoruSirala = React.lazy(() => import('../pages/apps/Soru/Sirala'))
// SSS
const FaqEkle = React.lazy(() => import('../pages/apps/Faq/Ekle'))
const FaqDuzenle = React.lazy(() => import('../pages/apps/Faq/Duzenle'))
// Brosür
const BrosurEkle = React.lazy(() => import('../pages/apps/Brosur/Ekle'))
const BrosurDuzenle = React.lazy(() => import('../pages/apps/Brosur/Duzenle'))
const brosurDetay = React.lazy(() => import('../pages/apps/Brosur/Detay'))
// Anketler
const Anketler = React.lazy(() => import('../pages/apps/Anketler/Anketler'))
// Bildirim
const Bildirim = React.lazy(() => import('../pages/apps/Bildirim/Bildirim'))
// AnketCevapla
const AnketCevapla = React.lazy(() =>
  import('../pages/apps/AnketCevapla/Cevapla')
)
// handle auth and authorization
const PrivateRoute = ({ component: Component, roles, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      if (!isUserAuthenticated()) {
        // not logged in so redirect to login page with the return url
        return (
          <Redirect
            to={{ pathname: '/account/login', state: { from: props.location } }}
          />
        )
      }
      const loggedInUser = getLoggedInUser()

      // check if route is restricted by role
      if (roles && roles.indexOf(loggedInUser?.role) === -1) {
        // role not authorised so redirect to home page
        return <Redirect to={{ pathname: '/' }} />
      }

      // authorised so return component
      return <Component {...props} />
    }}
  />
)

// root routes
const rootRoute = {
  path: '/',
  exact: true,
  component: () => <Redirect to={'/anasayfa'} />,
  route: PrivateRoute,
}

// dashboards
const dashboardRoutes = {
  path: '/anasayfa',
  name: 'Anasayfa',
  icon: FeatherIcon.Home,
  component: Dashboard,
  roles: ['Admin', 'Recommender'],
  route: PrivateRoute,
}

const kullaniciRoutes = {
  path: '/kullanicilar',
  component: KayitliKullanicilar,
  name: 'Kullanıcı İşlemleri',
  icon: FeatherIcon.User,
  route: PrivateRoute,
  roles: ['Admin'],
}

const yoneticiRoutes = {
  path: '/yonetici',
  name: 'Yönetici İşlemleri',
  icon: FeatherIcon.Award,
  children: [
    {
      path: '/yonetici/ekle',
      name: 'Yönetici Ekle',
      component: YoneticiEkle,
      route: PrivateRoute,
      roles: ['Admin'],
    },
    {
      path: '/yonetici/goruntule',
      name: 'Yönetici Görüntüle',
      component: YoneticiGoruntule,
      route: PrivateRoute,
      roles: ['Admin'],
    },
  ],
}
const yazarRoutes = {
  path: '/yazar',
  name: 'Yazar İşlemleri',
  icon: FeatherIcon.Edit3,
  children: [
    {
      path: '/yazar/ekle',
      name: 'Yazar Ekle',
      component: YazarEkle,
      route: PrivateRoute,
      roles: ['Admin'],
    },
    {
      path: '/yazar/goruntule',
      name: 'Yazar Görüntüle',
      component: YazarGoruntule,
      route: PrivateRoute,
      roles: ['Admin'],
    },
  ],
}

const soruRoutes = {
  path: '/soru',
  name: 'Anket Soru İşlemleri',
  icon: FeatherIcon.Circle,
  children: [
    {
      path: '/soru/ekle',
      name: 'Soru Ekle',
      component: SoruEkle,
      route: PrivateRoute,
      roles: ['Admin'],
    },
    {
      path: '/soru/goruntule',
      name: 'Soruları Görüntüle',
      component: SoruGoruntule,
      route: PrivateRoute,
      roles: ['Admin'],
    },
    {
      path: '/soru/siralama',
      name: 'Soruları Sıralaması',
      component: SoruSirala,
      route: PrivateRoute,
      roles: ['Admin'],
    },
  ],
}

const sssRoutes = {
  path: '/sss',
  name: 'S.S.S. İşlemleri',
  icon: FeatherIcon.HelpCircle,
  children: [
    {
      path: '/sss/ekle',
      name: 'S.S.S. Ekle',
      component: FaqEkle,
      route: PrivateRoute,
      roles: ['Admin'],
    },
    {
      path: '/sss/duzenle',
      name: 'S.S.S Düzenle',
      component: FaqDuzenle,
      route: PrivateRoute,
      roles: ['Admin'],
    },
  ],
}

const anketRoutes = {
  path: '/anketcevapla',
  component: AnketCevapla,
  name: 'Anket Cevapla',
  icon: FeatherIcon.Edit,
  route: PrivateRoute,
  roles: ['Admin', 'Recommender'],
}

const anketlerRoutes = {
  path: '/tumanketler',
  component: Anketler,
  name: 'Tüm Anketler',
  icon: FeatherIcon.Clipboard,
  route: PrivateRoute,
  roles: ['Admin'],
}

const bildirimRoutes = {
  path: '/mobilbildirim',
  component: Bildirim,
  name: 'Mobil Bildirim',
  icon: FeatherIcon.Bell,
  route: PrivateRoute,
  roles: ['Admin'],
}

// auth
const authRoutes = {
  path: '/account',
  name: 'Auth',
  children: [
    {
      path: '/account/login',
      name: 'Login',
      component: Login,
      route: Route,
    },
    {
      path: '/account/logout',
      name: 'Logout',
      component: Logout,
      route: Route,
    },
  ],
}

// flatten the list of all nested routes
const flattenRoutes = (routes) => {
  let flatRoutes = []

  routes = routes || []
  routes.forEach((item) => {
    flatRoutes.push(item)

    if (typeof item.children !== 'undefined') {
      flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)]
    }
  })
  return flatRoutes
}

// All routes
const allRoutes = [
  rootRoute,
  dashboardRoutes,
  kullaniciRoutes,
  yoneticiRoutes,
  yazarRoutes,
  soruRoutes,
  sssRoutes,
  anketRoutes,
  anketlerRoutes,
  // bildirimRoutes,
  authRoutes,
]

const authProtectedRoutes = [
  dashboardRoutes,
  kullaniciRoutes,
  yoneticiRoutes,
  yazarRoutes,
  soruRoutes,
  sssRoutes,
  anketRoutes,
  anketlerRoutes,
  //bildirimRoutes,
]

const allFlattenRoutes = flattenRoutes(allRoutes)
export { allRoutes, authProtectedRoutes, allFlattenRoutes }
